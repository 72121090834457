.masonry-item img {
  max-width: 100%;
}

.masonry-item {
  padding: 10px;
}

.masonry-container {
  // padding: 10px;
}

.gallery-overlay {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  background: rgb(0,0,0);
  opacity: 0;
  transition: opacity .2s, visibility .2s;
  &:hover {
    opacity: 0.7;
  }
}

.gallery-title {
  position: absolute;
  left: 20px;
  bottom: 20px;
  color: white;
}
