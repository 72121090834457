.image-overflow {
  @media only screen and (max-width: 992px) {
    max-width: 100%;
  }
  @media only screen and (min-width: 992px) {
    max-width: 150%;
  }
  margin-left: 50%;
  transform: translateX(-50%);
  max-height: 90vh;
  margin-top: 1em;
}

.image-caption {
  font-style: italic;
  display: block;
  padding: 2px;
  text-align: center;
}
