// Global styling for this template
body {
  font-size: 1.1em;
  @media only screen and (max-width: 640px) {
    left: 10%;
    font-size: 1em;
  }
  color: $gray-900;
  @include serif-font;
}

html, body {
  overflow-x: hidden;
}

p {
  line-height: 1.5;
  margin: 30px 0;
  a {
    text-decoration: underline;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 800;
  @include sans-serif-font;
}

a {
  color: $teal;
  @include transition-all;
}

blockquote {
  font-style: italic;
  color: $gray-600;
  border-left: 0.5rem solid $gray-400;
  padding-left: 1rem;
  margin-left: 2rem;
}

.section-heading {
  font-size: 36px;
  font-weight: 700;
  margin-top: 60px;
}

.caption {
  font-style: italic;
  display: block;
  margin: 0;
  padding: 10px;
  text-align: center;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

::-moz-selection {
  color: $white;
  background: $primary;
  text-shadow: none;
}

::selection {
  color: $white;
  background: $primary;
  text-shadow: none;
}

img::selection {
  color: $white;
  background: transparent;
}

img::-moz-selection {
  color: $white;
  background: transparent;
}
