.home-text {
  font-size: 3.3em;
  @include sans-serif-font;
  font-weight: 800;
  line-height: 1.3em;
}

.home-text-secondary {
  @include sans-serif-font;
  font-size: 2.5em;
  font-weight: 100;
  line-height: 1.3em;
}

.home-text-tertiary {
  @include sans-serif-font;
  font-size: 1.4em;
  font-weight: 100;
  line-height: 1.3em;
}

.home-text-link {
  text-decoration: none;
  color: $primary;
}

.home-image {
  max-width: 100%;
  @media only screen and (min-width: 768px) {
    max-height: 50vh;
  }
  border-radius: 10px;
}

