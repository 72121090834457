.photofeed-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1;
}

.photofeed-image-container {
  display: inline-block;
}

.photofeed-image-caption {
  font-style: italic;
  display: block;
  padding: 2px;
  text-align: center;
}

.photofeed-navigation-thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
