.book {
  text-transform: capitalize;

  &--cover {
    max-width: 100px !important;
    padding: 10px;
    margin-left: 0 !important;
    transform: unset !important;
    filter: drop-shadow(0 0 3px rgba(0,0,0,0.3));
  }
}

.book-effect-container {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scale(0.4);

  @media only screen and (min-width: 768px) {
    transform: scale(0.6);
    left: 60%;
  }
}

// https://codepen.io/lynnandtonic/pen/JjGmrBz
.book-effect {
  width: 18.5em;
  height: 23.0991em;
  margin-top: -4.4em;
  position: relative;
  transform: perspective(60em) rotateX(58deg) rotateZ(-34deg) skewY(-7deg);
  box-shadow: -1.4em 1.7em 0.3em -0.3em rgba(0,0,0,0.8), -1.6em 1.8em 0.9em -0.2em rgba(0,0,0,0.5), 0.3em 1.9em 1.3em rgba(0,0,0,0.3);
  border-top-right-radius: 0.4em;

  img {
    border-top-right-radius: 0.4em;
    box-sizing: border-box;
    width: 100%;
    clip: rect(0em, 18.5em, 23.1em, 0em);
    display: block;
    position: absolute;
    filter: saturate(90%);
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
  }

  &:before {
    width: 105%;
    height: 105%;
    left: -5%;
    z-index: -1;
    background-repeat: no-repeat;
    background-image: linear-gradient(115deg, transparent 2.8%, #3f3f3f 3%, #3f3f3f 16%, transparent 16%),
      linear-gradient(125deg, transparent 10%, #3f3f3f 10%, #3f3f3f 17%, #222 46.8%, transparent 47%),
      linear-gradient(125deg, transparent 46%, rgba(0,0,0,0.5) 46.5%, rgba(0,0,0,0.25) 49%, transparent 53%),
      linear-gradient(to right, #444, #666),
      linear-gradient(#444, #444), linear-gradient(140deg, transparent 45%, #eee 45%, #ccc 96.8%, rgba(170,170,170,0) 97%);
    background-size: 100% 100%, 100% 100%, 100% 100%, 100% 0.4em, 94% 0.2em, 100% 100%;
    background-position: 0 0, 0 0, 0 0, 0 95.8%, 0 100%, 0 0;
  }

  &:after {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(to right, transparent 2%, rgba(0,0,0,0.1) 3%, rgba(0,0,0,0.1) 4%, transparent 5%),
      linear-gradient(-50deg, rgba(0,0,0,0.1) 20%, transparent 100%),
      linear-gradient(-50deg, rgba(0,0,0,0.2) 20%, transparent 100%),
      linear-gradient(to bottom, rgba(0,0,0,0.1) 20%, transparent 100%),
      linear-gradient(to bottom, rgba(0,0,0,0.1) 20%, transparent 100%);
    background-size: 100% 100%, 2% 20%, 1% 20%, 2% 20%, 1% 20%;
    background-position: 0 0, 2.2% 100%, 3% 100%, 2.2% 0, 3% 0;
  }
}
