.game-thumbnail {
  margin: 10px;
  border: 2px solid black;
  display: inline-block;
  overflow: hidden;

  img {
    width: 101%;
  }

  &--favorite:before, &--favorite:after {
    content: '';
    position: absolute;
    bottom: 14px;
    top: 7px;
    left: 22px;
    right: 22px;
    background: linear-gradient(45deg, #ffff00, #ff3d01, #fb0094 );
    background-size: 400%;
    z-index: -1;
    animation: steam 20s linear infinite;
  }

  &--favorite:after {
    filter: blur(30px);
  }
}

.game-title {
  position: absolute;
  bottom: 18px;
  right: 27px;
  left: 27px;
  color: white;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.5) 35%, rgba(0,0,0,0) 100%);
  text-align: right;
  padding: 5px;
}

@keyframes steam {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
}

.game-achievements {
  float: left;
}
